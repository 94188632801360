import React, { ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PassInputProps {
  onChange: (e:ChangeEvent<HTMLInputElement>)=>void
  password: string
}

const PassInput: React.FC<PassInputProps> = ({ onChange, password }) => {
  return (
    <div className="field">
      <label className="label" htmlFor="">Password</label>
      <div className="control has-icons-left">
        <input
          type="password"
          className="input"
          placeholder="パスワードを入力"
          onChange={onChange}
          value={password}/>
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={['fas', 'lock']} />
        </span>
      </div>
    </div>
  )
}

export default PassInput
