import React from 'react'
import EventTable from './molecules/EventTable'
import EventInput from './molecules/EventInput'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../stores'
import { Event, addEvent } from '../stores/event'

const Dashboard: React.FC = () => {
  const { events } = useSelector((state:RootState) => state.event)
  const dispatch = useDispatch()

  const saveEvent = (newEvent: Event) => {
    if (
      events
        .filter((e:Event) => e.timestamp === newEvent.timestamp)
        .filter((e:Event) => e.name === newEvent.name)
        .length > 0
    ) {
      // 同一時間かつ同一名前のイベントがすでに存在する
      return new Error('同じ時間かつ同じ名前のイベントがすでに存在しています')
    }
    dispatch(addEvent(newEvent))
    return null
  }
  return (
    <div className="columns">
      <div className="column is-4">
        <EventInput handleSave={saveEvent}/>
      </div>
      <div className="column">
        <EventTable />
      </div>
    </div>
  )
}

export default Dashboard
