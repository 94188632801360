import React from 'react'
import Login from './components/Login'
import EventDetail from './components/EventDetail'
import Auth from './components/Auth'
import Dashboard from './components/Dashboard'
import WithHeader from './components/WithHeader'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

library.add(fab, fas, far)

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Auth>
          <WithHeader>
            <Switch>
              <Route path="/events/:eventID" component={EventDetail} />
              <Route exact path="/" component={Dashboard}/>
            </Switch>
          </WithHeader>
        </Auth>
      </Switch>
    </Router>
  )
}

export default App
