import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment'
import { ID, generateUuid } from '../utils/misc'

export interface Guest {
  id: ID;
  name: string;
}

export interface Participant {
  id: ID;
  name: string;
  choicies: ID[];
}

export type Event = {
  id: ID;
  name: string;
  timestamp: number;
  takuNumber: number;
  guests: Guest[];
  participants: Participant[];
}

export interface EventState {
  events: Event[];
}

interface UpdateGuestPayload {
  id: ID;
  newGuest: Guest;
}

interface AddGuestPayload {
  eventID: ID;
  newGuest: Guest;
}

const initiateEventsState: EventState = {
  events: []
}

const slice = createSlice({
  name: 'event',
  initialState: initiateEventsState,
  reducers: {
    addEvent: (state: EventState, action: PayloadAction<Event>) => {
      return Object.assign({}, state, {
        events: [...state.events, action.payload]
      })
    },
    updateEvent: (state: EventState, action: PayloadAction<Event>) => {
      return Object.assign(state, {
        events: [...state.events].map((elm) => {
          if (elm.id === action.payload.id) {
            return action.payload
          } else {
            return elm
          }
        })
      })
    },
    deleteEvent: (state: EventState, action: PayloadAction<ID>) => {
      return Object.assign(state, {
        events: [...state.events].filter((e: Event) => e.id !== action.payload)
      })
    },
    addGuest: (state: EventState, action: PayloadAction<AddGuestPayload>) => {
      return Object.assign(state, {
        events: [...state.events].map((e: Event) => {
          if (e.id === action.payload.eventID) {
            // ゲスト追加
            return Object.assign(e, { guests: [...e.guests, action.payload.newGuest] })
          } else {
            return e
          }
        })
      })
    }
  }
})

export default slice.reducer
export const { addEvent, updateEvent, deleteEvent, addGuest } = slice.actions
