import React from 'react'
import { useParams } from 'react-router-dom'
import EventOverview from './molecules/EventOverview'
import GuestInputs from './molecules/GuestInputs'
import ParticipantInputs from './molecules/ParticipantInputs'

const EventDetail: React.FC = () => {
  let { eventID } = useParams()
  if (eventID === undefined) {
    eventID = ''
  }
  return (
    <div>
      <div className="columns">
        <div className="column is-8">
          <EventOverview eventID={eventID}/>
        </div>
      </div>
      <div className="columns">
        <div className="column is-harf">
          <GuestInputs eventID={eventID}/>
        </div>
        <div className="column is-harf">
          <ParticipantInputs />
        </div>
      </div>
    </div>
  )
}

export default EventDetail
