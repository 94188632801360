import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Event } from '../../stores/event'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { generateUuid } from '../../utils/misc'

type Props = {
  name?: string;
  timestamp?: number;
  takuNumber?: number;
  handleSave: (e: Event)=>Error|null;
}

const EventInput: React.FC<Props> = (props: Props) => {
  const [name, setName] = useState(props.name ? props.name : '')
  const [date, setDate] = useState(props.timestamp ? moment(props.timestamp) : moment({ hour: 12 }))
  const [takuNumber, setTakuNumber] = useState(props.takuNumber ? props.takuNumber : 0)

  const [errMsg, setErrorMsg] = useState('')

  const resetState = () => {
    setName(props.name ? props.name : '')
    setDate(props.timestamp ? moment(props.timestamp) : moment({ hour: 12 }))
    setTakuNumber(props.takuNumber ? props.takuNumber : 0)
    setErrorMsg('')
  }

  const handleSave = () => {
    const newEvent: Event = {
      id: generateUuid(),
      name: name,
      timestamp: date.valueOf(),
      takuNumber: takuNumber,
      guests: [],
      participants: []
    }
    const err = props.handleSave(newEvent)
    if (err) {
      setErrorMsg(err.message)
      return
    }
    resetState()
  }

  const handleNotification = () => {
    if (errMsg !== '') {
      return (
        <div className="notification is-danger">
          <button className="delete" onClick={() => { setErrorMsg('') }}></button>
          {errMsg}
        </div>
      )
    }
  }
  return (
    <div className="box">
      <div className="field">
        <label className="label">イベント日</label>
        <div className="control">
          <DatePicker
            className="input"
            selected={date.toDate()}
            showTimeSelect
            dateFormat="yyyy-MM-dd hh:mm"
            onChange={(d) => { d !== null ? setDate(moment(d)) : setDate(moment()) }}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">イベント名</label>
        <div className="control">
          <input
            className="input"
            type="text"
            placeholder="イベント名を入力して下さい"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="field">
        <label className="label">卓数</label>
        <div className="field has-addons">
          <p className="control">
            <a className="button" onClick={() => setTakuNumber(takuNumber + 1)}>
              <FontAwesomeIcon icon={['fas', 'angle-double-up']} />
            </a>
          </p>
          <div className="control">
            <p className="input">{takuNumber}</p>
          </div>
          <p className="control">
            <a
              className="button"
              onClick={() => takuNumber <= 0 ? setTakuNumber(0) : setTakuNumber(takuNumber - 1)}>
              <FontAwesomeIcon icon={['fas', 'angle-double-down']} />
            </a>
          </p>
        </div>
      </div>
      <div className="control">
        <button
          className="button is-link"
          onClick={handleSave}>保存</button>
      </div>
      {handleNotification()}
    </div>
  )
}

export default EventInput
