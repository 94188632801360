import React, { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { logout } from '../stores/auth'
import logo from '../logo.png'

interface Props {
  children: ReactNode
}

const WithHeader: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch()
  const handleLogout = () => {
    dispatch(logout())
  }
  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-head">
        <header className="navbar">
          <div className="container is-fluid">
            <div className="navbar-brand">
              <img src={logo}/>
            </div>
            <div className="navbar-menu">
              <div className="navbar-end">
                <span className="navbar-item">
                  <a className="button" onClick={handleLogout} >
                    <span className="icon">
                      <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                    </span>
                    <span>ログアウト</span>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div id='dashboardbody' className="hero-body">
        <div className="container is-fluid">
          {children}
        </div>
      </div>
    </section>
  )
}

export default WithHeader
