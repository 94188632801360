import React, { useState, ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface EmailInputProps {
  onChange: (e:ChangeEvent<HTMLInputElement>)=>void
  email: string
}

const EmailInput: React.FC<EmailInputProps> = ({ onChange, email }) => {
  return (
    <div className="field">
      <label className="label" htmlFor="">Email</label>
      <div className="control has-icons-left has-icons-right">
        <input
          type="text"
          className="input"
          placeholder="Emailを入力"
          onChange={onChange}
          value={email}/>
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={['fas', 'envelope']}></FontAwesomeIcon>
        </span>
      </div>
    </div>
  )
}

export default EmailInput
