import React from 'react'
import ParticipantField from './ParticipantField'

const ParticipantInputs: React.FC = () => {
  return (
    <div className="box">
      <label className="label">参加者を入力</label>
      <ParticipantField />
      <ParticipantField />
      <ParticipantField />
    </div>
  )
}

export default ParticipantInputs
