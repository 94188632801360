import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../stores'
import { Redirect } from 'react-router-dom'
import { auth } from '../utils/firebase'
import { login, logout } from '../stores/auth'

interface AuthProps {
  children: ReactNode
}

const Auth: React.FC<AuthProps> = ({ children }) => {
  const { isAuthed } = useSelector((state:RootState) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(login({ email: user.email }))
      } else {
        dispatch(logout())
      }
    })
  }, [children])

  if (isAuthed) {
    return <div>{children}</div>
  } else {
    return <Redirect to="/login" />
  }
}

export default Auth
