import React from 'react'

const ParticipantField: React.FC = () => {
  return (
    <div className="field">
      <div className="field-body">
        <div className="field">
          <div className="control"><input className="input" type="" /></div>
        </div>
        <div className="field">
          <div className="control">
            <div className="select is-fullwidth">
              <select>
                <option>同卓第一希望</option>
                <option>Marketing</option>
                <option>Sales</option>
              </select>
            </div>
          </div>
        </div>
        <div className="field">
          <div className="control">
            <div className="select is-fullwidth">
              <select>
                <option>同卓第二希望</option>
                <option>Marketing</option>
                <option>Sales</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ParticipantField
