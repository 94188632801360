import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Guest, Event, addGuest } from '../../stores/event'
import { RootState } from '../../stores'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { guestUuid } from '../../utils/misc'

interface EventInputsProps {
  eventID: string;
}

const GuestInputs: React.FC<EventInputsProps> = ({ eventID }) => {
  const dispatch = useDispatch()
  const { events } = useSelector((state:RootState) => state.event)

  const [guestName, setGuestName] = useState('')
  const [errMsg, setErrMsg] = useState('')
  const notificationArea = () => {
    if (errMsg !== '') {
      return (
        <div className="notification is-danger">
          <button className="delete" onClick={() => setErrMsg('')}></button>
          {errMsg}
        </div>
      )
    }
  }
  const guestTableBody = () => {
    return events.filter(e => e.id === eventID).map(e => {
      return e.guests.map(g => {
        return (
          <tr key={g.id}>
            <td>{g.name}</td>
            <td><a onClick={() => {}}><FontAwesomeIcon icon={['fas', 'edit']} /></a></td>
            <td><a onClick={() => {}}><FontAwesomeIcon icon={['fas', 'trash-alt']} /></a></td>
          </tr>
        )
      })
    })
  }
  const saveHandler = () => {
    if (guestName === '') {
      // ゲスト名未入力
      setErrMsg('ゲストの名前が入力されていません')
      return
    }

    const newGuest = {
      id: guestUuid(),
      name: guestName
    }
    dispatch(addGuest({ eventID: eventID, newGuest: newGuest }))
    setGuestName('')
  }
  return (
    <div className="box">
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">ゲスト名</label>
        </div>
        <div className="field-body">
          <div className="field has-addons">
            <div className="control is-expanded">
              <input
                className="input"
                type="text"
                placeholder="ゲスト名を入力してください"
                onChange={(e) => setGuestName(e.target.value)}
                value={guestName}
              />
            </div>
            <div className="control">
              <a className="button is-info" onClick={saveHandler}>保存</a>
            </div>
          </div>
        </div>
      </div>
      {notificationArea()}
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>名前</th>
            <th>編集</th>
            <th>削除</th>
          </tr>
        </thead>
        <tbody>
          {guestTableBody()}
        </tbody>
      </table>
    </div>
  )
}

export default GuestInputs
