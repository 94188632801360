import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { auth } from '../utils/firebase'

export interface UserInfo {
    email: string | null;
}

export interface AuthState {
    isAuthed: boolean;
    info: UserInfo;
}

const initiateState: AuthState = {
  isAuthed: false,
  info: {
    email: ''
  }
}

const slice = createSlice({
  name: 'auth',
  initialState: initiateState,
  reducers: {
    login: (state: AuthState, action: PayloadAction<UserInfo>) => {
      return Object.assign(state, { isAuthed: true, info: { email: action.payload.email } })
    },
    logout: (state:AuthState) => {
      auth.signOut()
      return Object.assign({}, initiateState)
    }
  }
})

export default slice.reducer
export const { login, logout } = slice.actions
