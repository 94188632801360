import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'

const firebaseConfig = {
  apiKey: 'AIzaSyB80y9SGJVYjJgL11zoioyxUh3IYkuBne4',
  authDomain: 'tools-a3ef5.firebaseapp.com',
  databaseURL: 'https://tools-a3ef5.firebaseio.com',
  projectId: 'tools-a3ef5',
  storageBucket: 'tools-a3ef5.appspot.com',
  messagingSenderId: '97378965598',
  appId: '1:97378965598:web:3b0845dddca178bdc23ccf',
  measurementId: 'G-8P47Q6C7HB'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const auth = firebase.auth()
