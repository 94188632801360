import { combineReducers } from 'redux'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import eventReducer, { EventState } from './event'
import authReducer, { AuthState } from './auth'
import storage from 'redux-persist/lib/storage'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";

const reducer = combineReducers({
  event: eventReducer,
  auth: authReducer
})

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['auth']
}

const persistedReducer = persistReducer(persistConfig, reducer)

export interface RootState {
    event: EventState
    auth: AuthState
}

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})
export const persistor = persistStore(store)
