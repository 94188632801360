import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ID } from '../../utils/misc'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../stores'
import { Event } from '../../stores/event'
import moment from 'moment'

interface Props {
  eventID: ID;
}

const EventOverview: React.FC<Props> = ({ eventID }) => {
  const { events } = useSelector((state:RootState) => state.event)

  const elementByEvents = events.filter(e => e.id === eventID).map((e, i) => {
    return (
      <div key={i} className="level">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">イベント日</p>
            <p className="title">{moment(e.timestamp).format('YYYY-MM-DD hh:mm')}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">イベント名</p>
            <p className="title">{e.name}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">卓数</p>
            <p className="title">{e.takuNumber}</p>
          </div>
        </div>
      </div>
    )
  })
  return (
    <div>
      {elementByEvents}
    </div>
  )
}

export default EventOverview
