// import {useState}, React from 'react'
import React, { useState, ChangeEvent, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../stores'
import { useHistory } from 'react-router-dom'
import { login, logout } from '../stores/auth'
import EmailInput from './molecules/EmailInput'
import PassInput from './molecules/PassInput'
import { auth } from '../utils/firebase'
import { mapingFirebaseAuthError } from '../utils/authErrorMapper'

const Login: React.FC = () => {
  const { isAuthed } = useSelector((state:RootState) => state.auth)
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [email, setEmail] = useState('')
  const handleOnChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }
  const [password, setPassword] = useState('')
  const handleOnChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }
  const history = useHistory()

  const handleLogin = (e:React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    auth.signInWithEmailAndPassword(email, password).then(res => {
      if (res.user) {
        dispatch(login({ email: res.user.email }))
        history.push('/')
      } else {
        dispatch(logout())
      }
    }).catch(err => {
      setErrorMsg(mapingFirebaseAuthError(err))
      setIsLoading(false)
    })
  }

  useEffect(() => {
    if (isAuthed) {
      history.push('/')
    }
  }, [isAuthed])

  const loadArea = () => {
    if (isLoading) {
      return <progress className="progress is-small is-danger" max="100">15%</progress>
    } else if (errorMsg !== '') {
      return (
        <div className="notification is-danger">
          <button className="delete" onClick={() => setErrorMsg('')}></button>
          <p>{errorMsg}</p>
        </div>
      )
    } else {
      return (
        <form action="" className="box">
          <EmailInput onChange={handleOnChangeEmail} email={email} />
          <PassInput onChange={handleOnChangePassword} password={password}/>
          <div className="field">
            <button className="button is-success" onClick={handleLogin}>ログイン</button>
          </div>
        </form>

      )
    }
  }

  return (
    <section className="hero is-primary is-fullheight">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-3-widescreen">
              {loadArea()}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login
