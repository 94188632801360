import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Event, updateEvent, deleteEvent } from '../../stores/event'
import { RootState } from '../../stores'
import moment from 'moment'
import { ID } from '../../utils/misc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EventInput from './EventInput'

const EventTable: React.FC = () => {
  const { events } = useSelector((state:RootState) => state.event)
  const dispatch = useDispatch()

  const [editEventID, setEditEventID] = useState('')
  const [deleteEventID, setDeleteEventID] = useState('')

  const orderInDate = (a: Event, b: Event) => {
    return b.timestamp - a.timestamp
  }

  const delEvent: (id:ID)=>void = (id: ID) => {
    if (events.filter((e: Event) => e.id === id).length <= 0) {
      setDeleteEventID('')
      return
    }
    setDeleteEventID(id)
  }

  const editEvent: (id:ID)=>void = (id: ID) => {
    if (events.filter((e: Event) => e.id === id).length <= 0) {
      setEditEventID('')
      return
    }
    setEditEventID(id)
  }

  const updateEventHandler = (newEvent: Event) => {
    if (
      events
        .filter((e:Event) => e.id !== editEventID)
        .filter((e:Event) => e.timestamp === newEvent.timestamp)
        .filter((e:Event) => e.name === newEvent.name)
        .length > 0
    ) {
      // 同一時間かつ同一名前のイベントがすでに存在する
      return new Error('同じ時間かつ同じ名前のイベントがすでに存在しています')
    }
    const tmp = events.filter((e: Event) => e.id === editEventID)
    if (tmp.length <= 0) {
      return new Error('エラーが発生しています')
    }
    const tmpObj = Object.assign({}, tmp[0])
    dispatch(
      updateEvent(
        Object.assign(
          tmpObj, { name: newEvent.name, timestamp: newEvent.timestamp, takuNumber: newEvent.takuNumber }
        )
      )
    )
    setEditEventID('')
    return null
  }

  const deleteEventHandler = (id: ID) => {
    dispatch(deleteEvent(id))
    setDeleteEventID('')
  }

  return (
    <div>
      <table className="table is-fullwidth">
        <thead>
          <tr>
            <th>イベント日</th>
            <th>イベント名</th>
            <th>卓数</th>
            <th>編集</th>
            <th>削除</th>
          </tr>
        </thead>
        <tbody>
          {events.slice().sort(orderInDate).map((e: Event) => {
            return (
              <tr key={e.id}>
                <th>{moment(e.timestamp).format('YYYY-MM-DD HH:mm')}</th>
                <td><Link to={`/events/${e.id}`}>{e.name}</Link></td>
                <td>{e.takuNumber}</td>
                <td><a onClick={() => { editEvent(e.id) }}><FontAwesomeIcon icon={['fas', 'edit']} /></a></td>
                <td><a onClick={() => { delEvent(e.id) }}><FontAwesomeIcon icon={['fas', 'trash-alt']} /></a></td>
              </tr>
            )
          })
          }
        </tbody>
      </table>
      {
        events
          .filter((e: Event) => e.id === editEventID)
          .map((e: Event, i: number) => {
            return (
              <div key={i} className={'modal' + ' ' + (editEventID !== '' ? 'is-active' : '')}>
                <div className="modal-background" onClick={() => { setEditEventID('') }}></div>
                <div className="modal-content">
                  <EventInput
                    handleSave={updateEventHandler}
                    name={e.name}
                    timestamp={e.timestamp}
                    takuNumber={e.takuNumber}/>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => { setEditEventID('') }}/>
              </div>
            )
          })
      }
      {
        events
          .filter((e: Event) => e.id === deleteEventID)
          .map((e: Event, i: number) => {
            return (
              <div key={i} className={'modal' + ' ' + (deleteEventID !== '' ? 'is-active' : '')}>
                <div className="modal-background" onClick={() => { setDeleteEventID('') }}></div>
                <div className="modal-content">
                  <div className="box">
                    <div className="notification is-danger">
                      <div className="content">
                        <p>イベント開催日: {moment(e.timestamp).format('YYYY-MM-DD hh:mm')}</p>
                        <p>イベント名: {e.name}</p>
                        <p>卓数: {e.takuNumber}</p>
                      </div>
                    </div>
                    <div className="buttons">
                      <button
                        className="button is-danger is-light"
                        onClick={() => deleteEventHandler(deleteEventID) }
                      >このイベントを削除します</button>
                      <button
                        className="button is-info is-light"
                        onClick={() => { setDeleteEventID('') }}
                      >キャンセル</button>
                    </div>
                  </div>
                </div>
                <button className="modal-close is-large" aria-label="close" onClick={() => { setDeleteEventID('') }}/>
              </div>
            )
          })
      }
    </div>
  )
}

export default EventTable
